@import '~@reasoncorp/kyber-js/dist/scss/themes/misuite-v2/theme-misuite-v2';

.cap-resolution-group {
  .card:last-child {
    margin-bottom: 0 !important;
  }
}

// review-sheet multi question
.review-sheet-multi-question {
  .review-sheet-multi-question-item {
    background: #FEFEFE;
    border: 1px solid #CCC;
    box-shadow: 0 0 2px 1px rgba(0,0,0,0.08);
  }
}

// review-sheet/cap v3
.review-sheet-v3-signature {
  .invalid-feedback {
    font-weight: bold;
    margin-right: 20px;
  }

  .review-sheet-v3-signature-text {
    margin-left: -6%;
  }
}

.review-sheet-v3, .cap-v3 {
  .form-group label.label-static, .form-group label.label-placeholder {
    color: $mi-primary-color;
    font-weight: bold;
    opacity: 1;
    margin-bottom: 6px;
  }

  .review-sheet-v3-question-row {
    border-bottom: 5px solid #DDD;
  }

  table thead {
    background: #fafafa;
  }

  tr.bottom-table-row td {
    border-bottom: 5px solid #dee2e6;
  }
}

.table-striped tbody tr:nth-of-type(odd){
  background: #fafafa;
}

span.follow-up-modal-local-unit{
  color: #FFC3C3 !important;
}

.cap-uploads-card {
  ol {
    padding: 0;
    counter-reset: item;
  }

  ol > li {
    padding: 0 0 0 2em;
    text-indent: -2em;
    list-style-type: none;
    counter-increment: item;
  }

  ol > li:before {
    display: inline-block;
    width: 1.5em;
    padding-right: 0.5em;
    font-weight: bold;
    text-align: right;
    font-size: 1.025rem;
    content: counter(item) ".";
  }

  li {
    padding-bottom: 10px;
  }

}

.FilingCabinetButton {
  font-size: 1.25rem;
  color: map-get($theme-colors, primary);
  border-radius: 19px;
  padding: 0 0.5rem 0 0.5rem;
  margin-left: -5px;

  span.filing-cabinet-badge {
    margin-top: -12px;
    margin-right: -5px;
    font-size: 2.5rem;
  }
}

.FilingCabinetButton:hover {
  background: #CCC;
}
